import { EventEmitter } from "eventemitter3";

const EE = new EventEmitter();

export const EVENT_TIMELINE_OVERLAY_MOUSE_UP = "timeline overlay mouse up";
export const EVENT_CESIUM_VIEWER_READY = "viewer ready";
export const EVENT_CESIUM_RESIZE = "viewer should resize";
export const EVENT_ENTITY_ON_HOVER = "entity on Hover";
export const EVENT_FLY_TO_ON_MAP = "fly to lat/long on map";

// Show Spectrum Occupancy flyout
export const EVENT_SHOW_SPECTRUM_OCCUPANCY = "spectrum button has been clicked";

// Trigger update of data by data source groups
export const EVENT_SPECTRUM_OCCUPANCY_DATA_UPDATED =
  "data by data source has been updated";

export const EVENT_TIMELINE_EXTERNAL_TIME_ADJUSTMENT =
  "adjust cesium and timeline start date from source outside of timeline";

// Events for selecting icons on table or map
export const EVENT_ENTITY_HIGLIGHTED_ON_MAP =
  "entity has been clicked/highlighted on map";
export const EVENT_ENTITY_NOT_HIGLIGHTED_ON_MAP =
  "entity has been not clicked/highlighted";
export const EVENT_ONE_ENTITY_HIGLIGHTED_FROM_TABLE =
  "entity has been highlighted on the table";
export const EVENT_SELECTED_ENTITIES_FROM_TABLE =
  "multiple entity have been highlighted on the table";
export const EVENT_SHOW_ENTITY_POPOVER_ON_MAP =
  "show the entity popover on the map";
export const EVENT_HIDE_ENTITY_POPOVER_ON_MAP =
  "hide the entity popover on the map";

// Events for selecting PPA endpoints
export const EVENT_PPA_ENDPOINTS_CLEAR = "CLEAR ppa endpoints";
export const EVENT_PPA_EDITED = "ppa was edited";
export const EVENT_PPA_DELETED = "ppa was deleted";
export const EVENT_PPA_CANCELLED = "ppa was deleted";
export const EVENT_PPA_SELECTION = "start ppa selection";
export const EVENT_PPA_TOGGLE_LOADING =
  "Triggers toggle for loading status in PPA list";
export const EVENT_PPA_LINK_FORM_TO_POINT =
  "link open form data to ppa adjustment buttons";
export const EVENT_PPA_UPDATE_FORM_COORDINATES =
  "update coordinates in ppa form from point selection on map";
export const EVENT_TOGGLE_PROPANALYSIS_VISIBILITY =
  "toggle prop analysis visibility";

// Events for selecting Proploss endpoints
export const EVENT_PROPLOSS_ENDPOINTS_SELECT_TX = "select proploss tx endpoint";
export const EVENT_PROPLOSS_ENDPOINTS_SELECT_RADIUS =
  "select proploss radius endpoint";
export const EVENT_PROPLOSS_ENDPOINTS_CLEAR = "CLEAR proploss endpoints";
export const EVENT_PROPLOSS_EDITED = "proploss was edited";
export const EVENT_PROPLOSS_DELETED = "proploss was deleted";
export const EVENT_PROPLOSS_CANCELLED = "proploss was cancelled";
export const EVENT_PROPLOSS_SELECTION = "start proploss selection";
export const EVENT_PROPLOSS_TOGGLE_LOADING =
  "Triggers toggle for loading status in Proploss list";
export const EVENT_TOGGLE_PROPLOSS_SELECTION_MODE_ENABLED =
  "enable/disable proploss selection mode";
export const EVENT_PROPLOSS_SHOW_ADJUSTMENT_BUTTON = "show adjustment icon";
export const EVENT_PROPLOSS_UPDATE_FORM_COORDINATES =
  "update coordinates in proploss form from point selection on map";
export const EVENT_PROPLOSS_LINK_FORM_TO_POINT =
  "link open form data to proploss adjustment button";

// Events for selecting RadarCoverage endpoints
export const EVENT_RADAR_COVERAGE_ENDPOINTS_SELECT_RADIUS =
  "select radar coverage radius endpoint";
export const EVENT_RADAR_COVERAGE_ENDPOINTS_CLEAR =
  "CLEAR radar coverage endpoints";
export const EVENT_RADAR_COVERAGE_EDITED = "radar coverage was edited";
export const EVENT_RADAR_COVERAGE_DELETED = "radar coverage was deleted";
export const EVENT_RADAR_COVERAGE_SELECTION = "start radar coverage selection";
export const EVENT_RADAR_COVERAGE_TOGGLE_LOADING =
  "Triggers toggle for loading status in Proploss list";
export const EVENT_RADAR_COVERAGE_UPDATE_FORM_COORDINATES =
  "update coordinates in radar coverage form from point selection on map";
export const EVENT_RADAR_COVERAGE_LINK_FORM_TO_POINT =
  "link open form data to radar coverage adjustment button";

// Events for selecting line of sight endpoints
export const EVENT_LOS_ENDPOINTS_SELECT_ORIGIN = "select los origin point";
export const EVENT_LOS_ENDPOINTS_SELECT_RANGE = "select los range";
export const EVENT_LOS_ENDPOINTS_CLEAR = "clear los endpoints";
export const EVENT_LOS_EDITED = "los was edited";
export const EVENT_LOS_DELETED = "los was deleted";
export const EVENT_LOS_SELECTION = "start los selection";
export const EVENT_LOS_TOGGLE_LOADING =
  "Triggers toggle for loading status in los list";
export const EVENT_LOS_UPDATE_FORM_COORDINATES =
  "update coordinates in los form from point selection on map";
export const EVENT_LOS_LINK_FORM_TO_POINT =
  "link open form data to los adjustment button";

// Events for selecting Multilink Budget endpoints
export const EVENT_MULTILINKBUDGET_EDITED = "Multilink Budget was edited";
export const EVENT_MULTILINKBUDGET_DELETED = "Multilink Budget was deleted";

// Events for selecting Radar Range Ring endpoints
export const EVENT_RADARRANGERING_SELECT_TX = "select radar location";
export const EVENT_RADARRANGERING_SELECTION = "start radar selection";
export const EVENT_RADARRANGERING_EDITED = "Radar Range Ring was edited";
export const EVENT_RADARRANGERING_DELETED = "Radar Range Ring was deleted";
export const EVENT_RADARRANGERING_CANCELLED = "Radar Range Ring was cancelled";
export const EVENT_RADARRANGERING_TOGGLE_LOADING =
  "Triggers toggle for loading status in Radar Probability list";
export const EVENT_RADAR_POINT_CLEAR = "CLEAR radar center point";
export const EVENT_RADAR_FORM_TO_POINT =
  "link open form data to radar adjustment buttons";
export const EVENT_RADAR_UPDATE_FORM_COORDINATES =
  "update coordinates in radar form from point selection on map";

// Generic event for an analysis being created, since the function called is the same
export const EVENT_PROPAGATION_ANALYSIS_CREATED =
  "A propagation analysis job has been created";
export const EVENT_PROPAGATION_ANALYSIS_COMPLETE =
  "A propagation analysis job has completed";
export const EVENT_PROPAGATION_ANALYSIS_CANCELED =
  "A propagation analysis job has been canceled";
export const EVENT_PROPAGATION_ANALYSIS_ERRORED =
  "A propagation analysis job has errored";

// Events for Map Drag and Drop
export const EVENT_DRAGGING_ENTITY_ON_MAP =
  "location for entity being dragged on map";

// Workspace Events
export const EVENT_WORKSPACE_CREATE_NEW = "create new workspace";
export const EVENT_WORKSPACE_LIST_TABLE_DESELECT_ROW =
  "Deselect selected table row";
export const EVENT_WORKSPACE_LIST_VIEW = "View workspace list";

// Excercise Events
export const EVENT_EXERCISE_GRID_ROW_SELECTED = "exercise grid row selected";
export const EVENT_EXERCISE_GRID_UPDATE_ROW_WITH_DATA =
  "update exercise grid with data";
export const EVENT_EXERCISE_GRID_REFRESH = "refresh exercise grid";
export const EVENT_EXERCISE_GRID_REFRESH_ALL =
  "refresh exercise grid with data";

// Events for AOI and Drawing Tools
export const EVENT_CREATE_AOI = "Creating AOI";
export const EVENT_TOGGLE_DRAWING_MODE_ENABLED = "Enable/Disable Drawing Mode";
export const EVENT_OPEN_AREA_FORM = "Open Draw Areas form";
export const EVENT_CLOSE_AREA_FORM = "Close Draw Areas form";
export const EVENT_AREA_EDITED = "Area edited";
export const EVENT_AREA_ADDED = "Area added";

export const EVENT_DRAGGABLE_WINDOW_ADD = "Add draggable window to workspace";
export const EVENT_DRAGGABLE_WINDOW_TOGGLE = "Toggle open draggable windows";
export const EVENT_DRAGGABLE_WINDOW_RESIZE = "Resize draggable window";
export const EVENT_DRAGGABLE_WINDOW_UPDATE = "Update window properties";

export const EVENT_CHART_WIDGET_ADD = "Add chart widget to workspace";
export const EVENT_CHART_WIDGET_CLOSE = "Close chart widget";

export const EVENT_OPS_CLOCK_WIDGET_ADD = "Add ops clock widget to workspace";
export const EVENT_OPS_CLOCK_WIDGET_CLOSE = "Close ops clock widget";

export const EVENT_WORKSPACE_SHARE_CONTROLLER_OPEN =
  "Opens workspace share controller";

export const EVENT_SPECTRUM_ASSIGNMENT_WIDGET_ADD =
  "Add spectrum assignment widget to workspace";
export const EVENT_SPECTRUM_ASSIGNMENT_WIDGET_CLOSE =
  "Close spectrum assignment widget";

export const EVENT_FILE_UPLOAD_ADD = "Open file upload panel";
export const EVENT_FILE_UPLOAD_CLOSE = "Close file upload panel";

export const EVENT_DRAWING_STARTED = "Drawing a shape has been started";
export const EVENT_DRAWING_COMPLETE = "Drawing a shape has been completed";

// Events for zindex service
export const EVENT_ZINDEX_INCREMENTED = "Z-index has been incremented";

// Events for clear workspace
export const CLEAR_WORKSPACE = "close all open windows";

// Events for TabularData anaylsis widget display
export const EVENT_TABULAR_DATA_WIDGET_ADD =
  "Add tabular-data widget to workspace";
export const EVENT_TABULAR_DATA_WIDGET_CLOSE = "Close tabular-data widget";

export const EVENT_GRID_LAYOUT_HEIGHT_CHANGE = "Change grid container height";

export const EVENT_SIDENAV_FLYOUT_TOGGLE_VISIBILITY =
  "toggle the flyout visibility";

export const EVENT_FILTER_AREA_INFO_FLYOUT = "Open filter info";

export const EVENT_TOGGLE_SATELLITE_VISIBILITY = "toggle visible satellites";

// CESIUM MOUSE EVENTS
export const EVENT_CESIUM_RIGHT_CLICK = "Cesium right click mouse event.";
export const EVENT_CESIUM_LEFT_CLICK = "Cesium left click mouse event.";
export const EVENT_CESIUM_LEFT_DOWN = "Cesium left mouse down event.";
export const EVENT_CESIUM_LEFT_UP = "Cesium left mouse up event.";
export const EVENT_CESIUM_LEFT_DOUBLE_CLICK =
  "Cesium left double click mouse event";
export const EVENT_CESIUM_MOUSE_MOVE = "Cesium mouse hover event.";
export const EVENT_CESIUM_CAMERA_MOVE_END = "Cesium map move end event";
export const EVENT_CESIUM_CAMERA_MOVE_START = "Cesium map move start event";

// OPS CLOCK COMPARISON
export const EVENT_OPS_CLOCK_COMPARISON_NEW = "Add new ops clock comparison";
export const EVENT_OPS_CLOCK_COMPARISON_ADD = "Add to ops clock comparison";

// BASEBALL CARDS
export const EVENT_BASEBALL_CARD_COMPARISON_NEW =
  "Add new baseball card to comparison list";
export const EVENT_BASEBALL_CARD_CAN_COMPARE =
  "Updates whether any more baseball cards can be compared";
export const EVENT_BASEBALL_CARD_CANCEL_COMPARE =
  "Cancels the ability to compare baseball cards";
export const EVENT_BASEBALL_CARD_TITLE_UPDATE =
  "Updates the header title for a baseball card";

// CAPABILITY COMPARISON
export const EVENT_CAPABILITY_COMPARISON_OPEN =
  "Opens a capability comparison draggable window";
export const EVENT_CAPABILITY_COMPARISON_CLOSE =
  "Closes a capability comparison window";
export const EVENT_CAPABILITY_COMPARISON_CONFIGURATIONS_OPEN =
  "Opens a capability comparison categories draggable window";
export const EVENT_CAPABILITY_COMPARISON_CONFIGURATIONS_CLOSE =
  "Closes a capability comparison categories window";

// Jobs Table Events
export const EVENT_JOBS_TABLE_OPEN = "open the all jobs table";

// NOTIFICATIONS
export const MANAGE_NOTIFICATIONS_WINDOW_OPEN =
  "open manage notifications window";

// WEATHER
export const EVENT_WEATHER_LAYERS_VIEW = "open weather layer categories window";

// FEEDBACK
export const EVENT_FEEDBACK_FORM_OPEN = "open user feedback form";

// LEGENDS
export const EVENT_LEGENDS_ENABLED_TOGGLE = "open legends";
export const EVENT_VISIBLE_LEGENDS_TOGGLE = "opens specific visible legends";

// HELP MENU
export const EVENT_HELP_MENU_TOOLTIPS_DESELECTED =
  "Deselects the tooltip option in Help menu";
export const EVENT_HELP_MENU_TOUR_SELECTED =
  "Indicates whether the tour option is in a selected state";

// ONBOARDING TOUR
export const EVENT_ONBOARDING_TOUR_ENABLED =
  "Indicates whether the onboarding tour is enabled or disabled";

// SIDENAV COMPANION
export const EVENT_SIDE_NAV_COMPANION_OPEN =
  "Opens a window in the side nav companion";
export const EVENT_SIDE_NAV_COMPANION_CLOSE =
  "Closes a window in the side nav companion";

// TIMELINE
export const EVENT_OPEN_START_DATE_PICKER = "Opens timeline start date picker";

// DATA LAYER TABS
export const EVENT_CHANGE_DATA_LAYER_TAB =
  "Changes which Data Layer Tab is open";

export const EVENT_SHOW_RECORD_COUNT_MONITOR = "Show the record count monitor";

export const EVENT_MEASUREMENT_TOOL_WINDOW_BUTTON_CLICK =
  "Opens the measurement tool window";

// GOAT
export const EVENT_GOAT_REPORT_WINDOW_OPEN = "Open GOAT report detail window";
export const EVENT_GOAT_WINDOW_BRING_TO_FRONT = "Bring GOAT window to front";
export const EVENT_GOAT_LISTINGS_WINDOW_OPEN = "Open GOAT data listings window";
export const EVENT_GOAT_WINDOW_CLOSE = "Close a GOAT window";
export const EVENT_GOAT_OVERLAY_UPDATE =
  "Triggers an update of displayed overlay";
export const EVENT_GOAT_DISPLAY_STATE =
  "Indicates whether GOAT is in a state available to display";

export default EE;
